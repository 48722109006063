'use client'

type Props = {
  text: string
  url: string
  onClick?: () => void
}

const LinkSectionItem = ({ text, url, onClick }: Props) => (
  <li className="main-footer__links-section-row">
    <a className="main-footer__links-section-link" href={url} onClick={onClick}>
      {text}
    </a>
  </li>
)

export default LinkSectionItem
