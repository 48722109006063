import { Divider } from '@vinted/web-ui'

import SeparatedList from 'components/SeparatedList'

import LinksSection from './LinksSection'
import PrivacySection from './PrivacySection'
import SocialSection from './SocialSection'

type Props = {
  isDigitalLawEnabled: boolean
  isBusinessInfoLinksEnabled: boolean
  onlineComplaintUrl: string | undefined
}

const MainFooter = ({
  isDigitalLawEnabled,
  isBusinessInfoLinksEnabled,
  onlineComplaintUrl,
}: Props) => {
  return (
    <footer className="main-footer">
      <div className="main-footer__content tear tear--top">
        <div className="container">
          <SeparatedList separator={<Divider />}>
            <LinksSection isBusinessInfoLinksEnabled={isBusinessInfoLinksEnabled} />
            <SocialSection />
            <PrivacySection
              isDigitalLawEnabled={isDigitalLawEnabled}
              isBusinessInfoLinksEnabled={isBusinessInfoLinksEnabled}
              onlineComplaintUrl={onlineComplaintUrl}
            />
          </SeparatedList>
        </div>
      </div>
    </footer>
  )
}

export default MainFooter
