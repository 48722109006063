'use client'

import { Image } from '@vinted/web-ui'

import { SOCIAL_FACEBOOK_URL, SOCIAL_INSTAGRAM_URL, SOCIAL_LINKEDIN_URL } from 'constants/routes'
import AppButton from 'components/AppStoreButtons/AppButton'
import useAsset from 'hooks/useAsset'

const SocialSection = () => {
  const asset = useAsset('/assets/icons/social-media')

  const renderSocialItem = (url: string, logo: string, altText: string) => (
    <li className="main-footer__social-media-item">
      <a
        className="main-footer__social-media-link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={asset(logo)} alt={altText} />
      </a>
    </li>
  )

  return (
    <div className="main-footer__social-section">
      <ul className="main-footer__social-section-media">
        {renderSocialItem(SOCIAL_FACEBOOK_URL, 'facebook.svg', 'Facebook')}
        {renderSocialItem(SOCIAL_LINKEDIN_URL, 'linkedin.svg', 'LinkedIn')}
        {renderSocialItem(SOCIAL_INSTAGRAM_URL, 'instagram.svg', 'Instagram')}
      </ul>

      <ul className="main-footer__social-section-app-store">
        <li className="main-footer__social-app-store-item">
          <AppButton platform={AppButton.Platform.Ios} />
        </li>
        <li className="main-footer__social-app-store-item">
          <AppButton platform={AppButton.Platform.Android} />
        </li>
      </ul>
    </div>
  )
}

export default SocialSection
